import { apikeyBugsnagBeta } from "../index";
import { IEnvConfig } from "@aspen/model";
const protocol = "https:";
const staticHost = `${protocol}//static.beta.test-aspendigital.co/`;
export const alphaNodeConfig: IEnvConfig = {
  env: "alphaNode",
  host: {
    dev: `${protocol}//apipre2.aspendigital.co/`,
    prod: `${protocol}//apipre2.aspendigital.co/`
  },
  klineChartUrl: `${staticHost}klinechart/index.html`,
  tvChartUrl: "https://alphanode-tradingview.asportal.co",
  websocket: {
    host: "wss://wspre.aspendigital.co:8080"
  },
  apiKeyBugsnag: apikeyBugsnagBeta,
  webDomain: "https://partnerpre.aspendigital.co/",
  appOnlyDomain: "https://apponly.beta.test-aspendigital.co",
  domainUriPrefix: "https://betaaspendigital.page.link",
  fallbackUrl: {
    android: "https://www.pgyer.com/3dd275ece49d8e93df4c84461f258aed",
    ios: "https://www.pgyer.com/ec5209f9ab52485f233170905738b490"
  },
  packageName: {
    android: "com.beta.aspen",
    ios: "org.evg.beta.aspen"
  },
  minimumVersion: {
    android: "107",
    ios: "1.3.2"
  },
  operationServer: "https://apipre.aoperation.aspendigital.co/",
  saasServer: "https://api.beta-asportal.co/"
};
