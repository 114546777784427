import {
  DOMAIN_BETA,
  DOMAIN_DEV,
  DOMAIN_GREEN,
  DOMAIN_GREEN_MANAGER,
  DOMAIN_GREEN_PARTNER,
  DOMAIN_PROD,
  DOMAIN_PROD_MANAGER,
  DOMAIN_PROD_PARTNER,
  DOMAIN_SAAS_BETA,
  DOMAIN_SAAS_DEV,
  DOMAIN_SAAS_PROD,
  DOMAIN_ALPHA_NODE
} from ".";

const originCode: any = {
  [DOMAIN_PROD]: "G-VLZCZXXJSB",
  [DOMAIN_GREEN]: "G-VLZCZXXJSB",
  [DOMAIN_PROD_PARTNER]: "G-GN0XHEQGBT",
  [DOMAIN_GREEN_PARTNER]: "G-GN0XHEQGBT",
  [DOMAIN_PROD_MANAGER]: "G-1K2LBE78BY",
  [DOMAIN_GREEN_MANAGER]: "G-1K2LBE78BY",
  [DOMAIN_DEV]: "G-3QT8PK751H", // 和app 合并数据流
  [DOMAIN_BETA]: "G-EKK4QWXEZK",
  ["localhost"]: "G-EKK4QWXEZK",
  [DOMAIN_SAAS_DEV]: "G-2SKCHL88CC",
  [DOMAIN_SAAS_BETA]: "G-JSCRMHSZN3",
  [DOMAIN_SAAS_PROD]: "G-ZBQGSQK3NV",
  [DOMAIN_ALPHA_NODE]: ""
};

let apikeyGa = typeof window != "undefined" && originCode[location.hostname];

Object.keys(originCode).map((key: string) => {
  if ([DOMAIN_SAAS_DEV, DOMAIN_SAAS_BETA, DOMAIN_SAAS_PROD].includes(key)) {
    if (typeof window != "undefined" && location.hostname.includes(key)) {
      apikeyGa = originCode[key];
    }
  }
});
if (!apikeyGa && process.env.NEXT_PUBLIC_DOMAIN) {
  apikeyGa = originCode[DOMAIN_SAAS_PROD];
}

export default apikeyGa ?? "";
