// 需要vercel在配置NEXT_PUBLIC_HEAP_ENV，可选值为dev / beta / production
const _HEAP_CONFIG = {
  dev: "1213401251",
  beta: "2345100443",
  production: "316713064", // prod blue
  alphaNode: "316713064" // prod blue
};
let HEAP_CONFIG_ID = _HEAP_CONFIG.dev;
switch (process.env.NEXT_PUBLIC_HEAP_ENV) {
  case "beta":
    HEAP_CONFIG_ID = _HEAP_CONFIG.beta;
    break;
  case "production":
    HEAP_CONFIG_ID = _HEAP_CONFIG.production;
    break;
  case "alphaNode":
    HEAP_CONFIG_ID = _HEAP_CONFIG.alphaNode;
    break;
}
export { HEAP_CONFIG_ID };
